import logo from "assets/images/logo.svg";
import HelpButton from "atoms/HelpButton";
import { InfoBox, Text } from "components/Contents/styles/Contents.styled";
import HeaderSubpage from "components/HeaderSubpage";
import {
  HeaderWrapper,
  Logo,
  StickyWrapper,
  StyledLink,
} from "components/styles/Header.styled";
import { useAppContext } from "provider/AppProvider";
import getCompanionContent from "utils/content";
import { useReservationFromQueryParams } from "utils/helper";

const PageLayout = ({
  isHeaderSubPageVisible,
  title,
  subTitle,
  content,
  contentId,
  children,
}) => {
  const { lang, reservations } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();

  const locationContent = reservation.locationContent;
  const cabinContent = reservation.cabinContent;
  const unitGroupContent = reservation.unitGroupContent;

  const numBookings = reservations.length;
  const logoLink =
    numBookings > 1 ? "/" : "/reservation?reservation=" + reservationId;

  const contentItem = getCompanionContent(
    contentId,
    locationContent,
    unitGroupContent,
    cabinContent
  );

  return (
    <div>
      <HeaderWrapper sticky={false}>
        <StyledLink to={logoLink}>
          <Logo src={logo} alt="logo" />
        </StyledLink>
        {/* <button onClick={toggleLanguage}>{lang}</button> */}
        <HelpButton reservation={reservation}></HelpButton>
      </HeaderWrapper>
      <StickyWrapper>
        {isHeaderSubPageVisible ? (
          <HeaderSubpage h5Text={subTitle} h2Text={title} />
        ) : null}
      </StickyWrapper>
      <InfoBox>
        {children ? (
          children
        ) : (
          <Text
            className="subpageContent"
            dangerouslySetInnerHTML={{
              __html: content ? content : contentItem.content,
            }}
          />
        )}
      </InfoBox>
    </div>
  );
};

export default PageLayout;
