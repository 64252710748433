import { contents } from "data/ContentOutline";
import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import dict from "utils/translations";

function RegionalTips() {
  const { lang } = useAppContext();

  return (
    <PageLayout
      isHeaderSubPageVisible={contents[0].id === "beforeTrip"}
      title={dict("pages.tips", lang)}
      subTitle={dict("contentItems.beforeTrip.title", lang)}
      contentId="region_tips"
    />
  );
}

export default RegionalTips;
