import CollapsibleReservation from "atoms/CollapsibleReservation";
import Logout from "atoms/Logout";
import BasicFacts from "components/Contents/BasicFacts";
import {
  AlignIcon,
  BoxWrapper,
  DatesBox,
  DatesWrapper,
  H,
  InfoBox,
  P,
} from "components/Contents/styles/Contents.styled";
import Header from "components/Header";
import { StyledLink } from "components/styles/Header.styled";
import { contents } from "data/ContentOutline";
import { useAppContext } from "provider/AppProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dict from "utils/translations";
import infoIcon from "../assets/images/info.svg";
import { useReservationFromQueryParams } from "../utils/helper";

export default function Reservation({ ...props }) {
  const { lang, user } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !reservation) return navigate("/login");
  }, []);

  if (user && reservation) {
    return (
      <div>
        <Header />
        <BasicFacts reservation={reservation} />
        {contents.map((contentItem, index) => (
          <CollapsibleReservation
            key={index}
            reservation={reservation}
            contentItem={contentItem}
            {...contentItem}
          />
        ))}
        <BoxWrapper>
          <InfoBox></InfoBox>
          <DatesWrapper>
            <DatesBox>
              <H>{dict("contents.confirmation", lang)}</H>
              <P>#{reservation.id}</P>
            </DatesBox>
            <DatesBox>
              <AlignIcon>
                <H>{dict("pages.rate", lang)} </H>
                <StyledLink
                  to={"/reservation/details?reservation=" + reservation.id}
                >
                  <AlignIcon>
                    <img src={infoIcon} alt="health" />
                  </AlignIcon>
                </StyledLink>
              </AlignIcon>
              <P>{reservation.ratePlan.name[lang]}</P>
            </DatesBox>
          </DatesWrapper>
        </BoxWrapper>
        <Logout />
      </div>
    );
  }
}
