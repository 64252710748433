import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

export const getReservationFromQueryParams = (reservations, search) => {
  let params = queryString.parse(search);

  // Remove reservationId and reservation if they exist but have no value
  if (params.reservationId === "") delete params.reservationId;
  if (params.reservation === "") delete params.reservation;

  // Reconstruct the search string
  const updatedSearch = queryString.stringify(params);

  const reservationIdQueryParam =
    params?.reservation || params?.reservationId || false;

  const reservationId = reservationIdQueryParam || reservations?.[0]?.id;

  return { reservationId, updatedSearch };
};

// Update the useReservationFromQueryParams function to use the new return value
export const useReservationFromQueryParams = () => {
  const { getReservations, getReservationById } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const reservations = getReservations();
  const { reservationId, updatedSearch } = getReservationFromQueryParams(
    reservations,
    location.search
  );
  const reservation = getReservationById(reservationId);

  // useEffect(() => {
  //   const params = queryString.parse(location.search);
  //   if (params.reservation || params.reservationId) {
  //     const otherParams = Object.keys(params).filter(
  //       (key) => key !== "reservation" && key !== "reservationId"
  //     );
  //     console.log("otherParams", otherParams);

  //     if (otherParams.length === 0 && location.search !== "") {
  //       navigate(location.pathname, { replace: true });
  //     } else if (location.search !== updatedSearch) {
  //       navigate(`${location.pathname}?${updatedSearch}`, { replace: true });
  //     }
  //   }
  // }, [location.search, updatedSearch, navigate, location.pathname]);

  return {
    reservationId,
    reservation,
  };
};
