import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import dict from "utils/translations";

function HelpingHandGuide() {
  const { lang } = useAppContext();

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.helpingHandGuide", lang)}
      subTitle={dict("contentItems.helpingHandGuide.title", lang)}
      contentId="helping_hand_guide"
    />
  );
}

export default HelpingHandGuide;
