import successIcon from "assets/images/check_circle.svg";
import errorIcon from "assets/images/error.svg";
import infoIcon from "assets/images/info.svg";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
// import { useUpdateStayLongerData } from "hooks/useUpdateStayLongerData";
import { useAppContext } from "provider/AppProvider";
import { useState } from "react";
import stayLongerService from "services/stayLonger.service";
import styled from "styled-components";
import theme from "../pages/styles/Theme";
import { pSmall } from "../pages/styles/Typography";
import { getDefaultDateFormat } from "../utils/getDefaultDateFormat";
import dict from "../utils/translations";
import {
  AlignIcon,
  CardText,
  ErrorMessage,
  MarginP,
  NoMarginP,
  ThemedCard,
  ThemedCardInfo,
  ThemedCardTitle,
  UnderlinedP,
  UnderlinedPCentered,
} from "./Contents/styles/Contents.styled";
import { Button } from "./styles/Button.styled";
dayjs.extend(LocalizedFormat);

const StayLongerExplainer = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 0.25rem;
  ${pSmall};
  font-style: italic;
  text-align: center;
  color: ${theme.colors.grayDark};
`;

function StayLongerCard({
  reservation,
  daysBeforeArrival,
  isDepartureOnSunday,
}) {
  const { lang } = useAppContext();

  const singleNightPrice = 95;
  const stayLongerStatus = reservation?.upsellStatus?.status;
  const [buttonClicked, setButtonClicked] = useState(false); // for disabling button
  const [attemptedBooking, setAttemptedBooking] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [hideCard, setHideCard] = useState(
    localStorage.getItem("hideStayLongerCard")
  );

  const handleSubmit = async () => {
    setButtonClicked(true);
    setAttemptedBooking(true);

    let result = await stayLongerService.requestToStayLonger(reservation.id);
    if (result) {
      setAttemptedBooking(false);
    } else {
      setButtonClicked(false);
    }
  };

  const handleRemindMeLater = () => {
    setHideCard(true);
    localStorage.setItem("hideStayLongerCard", "true");
  };

  const missingData = !reservation || !stayLongerStatus || !singleNightPrice;
  const hasUpsellPotentialAfter = reservation?.upsellStatus?.possibleAfter;
  const hasUpsellPotentialBefore =
    daysBeforeArrival > 0 && reservation?.upsellStatus?.possibleBefore;
  const hasUpsellPotential =
    hasUpsellPotentialAfter || hasUpsellPotentialBefore;
  const arrivalIsFarInTheFuture = daysBeforeArrival >= 8;

  const offerUpsell =
    stayLongerStatus === "Erledigt" ||
    (!hideCard &&
      !missingData &&
      hasUpsellPotential &&
      (isDepartureOnSunday || !arrivalIsFarInTheFuture));

  // console.log("IN STAY LONGER -------------");
  // console.log("  >> reservation", reservation);
  // console.log("  >> daysBeforeArrival", daysBeforeArrival);
  // console.log("  >> singleNightPrice", singleNightPrice);
  // console.log("  >> stayLongerStatus", stayLongerStatus);
  // console.log("  >> missingData", missingData);
  // console.log("  >> arrivalIsFarInTheFuture", arrivalIsFarInTheFuture);
  // console.log("  >> hasUpsellPotential", hasUpsellPotential);
  // console.log("  >> isDepartureOnSunday", isDepartureOnSunday);
  // console.log("  >> hideCard", hideCard);
  // console.log("  >> offerUpsell", offerUpsell);

  if (!offerUpsell) {
    return null;
  }
  return (
    <ThemedCard theme={getThemeByStatus(stayLongerStatus)}>
      <CardText>
        <ThemedCardInfo>
          <AlignIcon noPadding={true}>
            <StatusIcon status={stayLongerStatus} />
            <div>
              <ThemedCardTitle theme={getThemeByStatus(stayLongerStatus)}>
                {dict("stayLonger.title", lang)}
              </ThemedCardTitle>
              <Message
                singleNightPrice={singleNightPrice}
                hasUpsellPotential={hasUpsellPotential}
                status={stayLongerStatus}
              />
            </div>
          </AlignIcon>
        </ThemedCardInfo>
        <div>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          {stayLongerStatus === "NoRequestExisting" && (
            <Form
              reservation={reservation}
              handleSubmit={handleSubmit}
              buttonClicked={buttonClicked}
              attemptedBooking={attemptedBooking}
              handleRemindMeLater={handleRemindMeLater}
            />
          )}

          {daysBeforeArrival >= 10 && stayLongerStatus !== "Erledigt" && (
            <UnderlinedPCentered onClick={handleRemindMeLater}>
              {dict("stayLonger.remindLater", lang)}
            </UnderlinedPCentered>
          )}
        </div>
      </CardText>
    </ThemedCard>
  );
}

const StatusIcon = ({ status }) => {
  if (status === "NoRequestExisting") return null;
  return (
    <img
      src={
        status === "CompletedNotPossible"
          ? errorIcon
          : status === "CompletedExtended" || status === "Erledigt"
          ? successIcon
          : infoIcon
      }
      alt="icon"
    />
  );
};

const Message = ({ status, hasUpsellPotential, singleNightPrice }) => {
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const { lang } = useAppContext();

  return (
    <>
      {status === "NoRequestExisting" && hasUpsellPotential && (
        <>
          <MarginP
            dangerouslySetInnerHTML={{
              __html: dict("stayLonger.textPossible", lang),
            }}
          />
          <MarginP
            dangerouslySetInnerHTML={{
              __html: dict("stayLonger.subtextPossible", lang, [
                singleNightPrice,
              ]),
            }}
          />
          <UnderlinedP
            onClick={() => setShowPaymentDetails(!showPaymentDetails)}
          >
            {dict("contents.exPriceFinal", lang)}
          </UnderlinedP>
          {showPaymentDetails && (
            <MarginP
              dangerouslySetInnerHTML={{
                __html: dict("stayLonger.paymentSubtextPossible", lang),
              }}
            />
          )}
        </>
      )}
      {(status === "Not Started" || status === "In Bearbeitung") && (
        <NoMarginP
          dangerouslySetInnerHTML={{
            __html: dict("stayLonger.textInProgress", lang),
          }}
        />
      )}
      {status === "Erledigt" && (
        <NoMarginP
          dangerouslySetInnerHTML={{
            __html: dict("stayLonger.textCompletedExtended", lang),
          }}
        />
      )}
      {/* {status === "CompletedNotPossible" && (
        <NoMarginP
          dangerouslySetInnerHTML={{
            __html: dict("stayLonger.textCompletedNotPossible", lang),
          }}
        />
      )} */}
    </>
  );
};

const Form = ({
  reservation,
  buttonClicked,
  attemptedBooking,
  handleSubmit,
}) => {
  const { lang } = useAppContext();

  const oneDayBefore = dayjs(reservation.arrival).subtract(1, "day");
  const oneDayAfter = dayjs(reservation.departure).add(1, "day");

  if (
    reservation.upsellStatus?.possibleAfter &&
    !reservation.upsellStatus?.possibleBefore
  ) {
    return (
      <>
        <Button
          className="primary"
          onClick={handleSubmit}
          $loading={buttonClicked}
          disabled={buttonClicked || attemptedBooking}
        >
          {buttonClicked && attemptedBooking
            ? dict("contents.waitRequestLoading", lang)
            : buttonClicked && !attemptedBooking
            ? dict("stayLonger.reqestSent", lang)
            : dict("stayLonger.optionAfter", lang)}
        </Button>
        <StayLongerExplainer>
          {dict("stayLonger.optionAfterExplainer", lang, [
            getDefaultDateFormat({
              date: oneDayAfter,
              locale: lang,
              withTime: true,
            }),
          ])}
        </StayLongerExplainer>
      </>
    );
  }

  // if (stayLongerStatus.possibleBefore && stayLongerStatus.possibleAfter) {
  //   return (
  //     <>
  //       <RadioBox>
  //         <div>
  //           <StyledRadio
  //             name="chosenOption"
  //             id="chosenOptionBefore"
  //             value="before"
  //             onChange={handleRadioChange}
  //           />
  //           <StyledLabel htmlFor="chosenOptionBefore" className="radio-label">
  //             {dict('stayLonger.optionBefore', lang)}
  //           </StyledLabel>
  //         </div>
  //         <div>
  //           <StyledRadio
  //             name="chosenOption"
  //             id="chosenOptionAfter"
  //             value="after"
  //             onChange={handleRadioChange}
  //           />
  //           <StyledLabel htmlFor="chosenOptionAfter" className="radio-label">
  //             {dict('stayLonger.optionAfter', lang)}
  //           </StyledLabel>
  //         </div>
  //         <div>
  //           <StyledRadio
  //             name="chosenOption"
  //             id="chosenOptionBoth"
  //             value="both"
  //             onChange={handleRadioChange}
  //           />
  //           <StyledLabel htmlFor="chosenOptionBoth" className="radio-label">
  //             {dict('stayLonger.optionBoth', lang)}
  //           </StyledLabel>
  //         </div>
  //       </RadioBox>
  //       <Button className="primary" onClick={handleSubmit} $loading={buttonClicked}>
  //         {buttonClicked
  //           ? dict('contents.waitRequestLoading', lang)
  //           : dict('stayLonger.submitButton', lang)}
  //       </Button>
  //     </>
  //   )
  // }
};

const getThemeByStatus = (status) => {
  switch (status) {
    case "NoRequestExisting":
    case "In Bearbeitung":
      return "blue";
    case "Erledigt":
      return "green";
    default:
      return "blue";
  }
};

export default StayLongerCard;
