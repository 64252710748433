import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import ButtonLink from "../../atoms/ButtonLink";
import dict from "../../utils/translations";
import { ContentBox, InfoBox } from "./styles/Contents.styled";

function HelpSecurity({ contents, daysDiff }) {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";

  const { lang } = useAppContext();

  if (daysDiff <= 3) {
    return (
      <ContentBox>
        {Object.keys(contents).map((key, index) => {
          return (
            <InfoBox key={index}>
              <ButtonLink
                caption={contents[key].title}
                to={"/" + key + "?reservation=" + reservationId}
              />
            </InfoBox>
          );
        })}
      </ContentBox>
    );
  } else {
    return <InfoBox>{dict("contents.tbaSecurity", lang)}</InfoBox>;
  }
}

export default HelpSecurity;
