import {
  BoxWrapper,
  ContentBox,
  H,
  InfoBox,
  P,
} from "components/Contents/styles/Contents.styled";
import { useAppContext } from "provider/AppProvider";
import { useEffect, useState } from "react";
import styled from "styled-components";
import down from "../../assets/images/keyboard_arrow_down.svg";
import up from "../../assets/images/keyboard_arrow_up.svg";
import {
  ContentWrapper,
  Icon,
  TileWithoutTopPadding,
  TileWrapper,
  Title,
  TitleWrapper,
} from "../../atoms/styles/Collapsible.styled.js";
import dict from "../../utils/translations";

const HostContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 15px;
`;

const HostImage = styled.img`
  max-width: 100%;
  border-top-right-radius: 32px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
`;

const HostInfo = ({ host }) => {
  const props = {
    id: "hostInfo",
  };

  const [expanded, setExpanded] = useState(
    localStorage.getItem(`expanded-${props.id}`) === "true" || true
  );

  useEffect(() => {
    localStorage.setItem(`expanded-${props.id}`, expanded);
  }, [expanded]);

  const { lang } = useAppContext();

  return (
    <div id={props?.id}>
      <TileWithoutTopPadding>
        <TileWrapper>
          <Title onClick={() => setExpanded(!expanded)}>
            <TitleWrapper>
              {/* <img src={props.icon} alt="icon" /> */}
              {dict(`hostInfo.title`, lang)}
            </TitleWrapper>
            <Icon>
              {expanded ? (
                <img src={up} alt="up-icon" />
              ) : (
                <img src={down} alt="down-icon" />
              )}
            </Icon>
          </Title>
        </TileWrapper>
        <ContentWrapper open={expanded}>
          <ContentBox>
            <BoxWrapper>
              <HostContent>
                <HostImage
                  src={
                    host.profile_image_url ||
                    "https://raus-sanity-default-images.fra1.cdn.digitaloceanspaces.com/host-profile-default.jpg"
                  }
                  alt="Host"
                />
                <InfoBox>
                  <H>
                    {dict("hostInfo.nameAndTeam", lang, [host.display_name])}
                  </H>
                  <P>
                    {dict("hostInfo.description", lang, [host.display_name])}
                  </P>
                </InfoBox>
              </HostContent>
            </BoxWrapper>
            <BoxWrapper>
              <HostContent>
                <div>
                  <H>{dict("hostInfo.phone", lang)}</H>
                  <P>
                    <a href={`tel:${host.guest_contact_phone}`}>
                      {host.guest_contact_phone}
                    </a>
                  </P>
                </div>
                <div>
                  <H>{dict("hostInfo.languages", lang)}</H>
                  <P>
                    {host.languages_spoken?.map((language, index) => (
                      <span key={language}>
                        {dict(`languages.${language}`, lang)}
                        {index < host.languages_spoken.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </P>
                </div>
              </HostContent>
            </BoxWrapper>
          </ContentBox>
        </ContentWrapper>
      </TileWithoutTopPadding>
    </div>
  );
};

export default HostInfo;
