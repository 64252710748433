export const calculateTotalPrice = (
  amount,
  count,
  quantity,
  isQuantityRequired,
  pricingUnit,
  availabilityMode
) => {
  // console.log("amount", amount);
  // console.log("count", count);
  // console.log("quantity", quantity);
  // console.log("isQuantityRequired", isQuantityRequired);
  // console.log("pricingUnit", pricingUnit);
  // console.log("availabilityMode", availabilityMode);

  let total = amount.grossAmount;
  if (isQuantityRequired) {
    total = (amount.grossAmount / count) * quantity; // for case 2 and case 4
  }

  if (
    pricingUnit === "Person" &&
    (availabilityMode === "Daily" || availabilityMode === "Arrival")
  ) {
    total = amount.grossAmount / count;
  }

  return `${total}€`;
};
