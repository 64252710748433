import * as Sentry from "@sentry/browser";
import axios from "axios";
import * as dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

const RAUS_API_URL = process.env.REACT_APP_RAUS_WEB_ENDPOINT;
const METADATA_URL = process.env.REACT_APP_METADATA_API_ENPOINT + "locations/";
const METADATA_TOKEN = process.env.REACT_APP_METADATA_TOKEN;

class ServiceService {
  enrichServiceFromMetadata(services, servicesAdditions) {
    // console.log("enrichServiceFromMetadata:services", services);
    // console.log(
    //   "enrichServiceFromMetadata:servicesAdditions",
    //   servicesAdditions
    // );
    if (
      !services ||
      services.length === 0 ||
      !servicesAdditions ||
      servicesAdditions.length === 0
    )
      return;

    // enrich the servicesAdditions from metadata with bookable services from apaleo
    let enrichedServices = servicesAdditions
      .map((enrichedService) => {
        // console.log("enrichedService.code ", enrichedService);
        const service = services.find((item) => {
          return item.service.code === enrichedService.code;
        });

        // no service found? skip this one because its not sellable on apaleo
        if (!service) {
          // console.log("no service found");
          return false;
        }
        // console.log("service", service);
        let updatedService = {
          ...service.service,
          count: service.count,
          dates: service.dates,
          prePaymentAmount: service.prePaymentAmount,
          totalAmount: service.totalAmount,
          activityStatus: service.activityStatus, // activityStatus is being set in enrichServiceWithActivityStatus which is called before this
        };

        // console.log("service", service);
        // console.log("service before", service);
        // console.log(
        //   "enrichedService.blocked_dates",
        //   enrichedService.blocked_dates
        // );
        // if (service.code === "CP_BF_ARC") {
        //   console.log("CP_BF_ARC");
        const datesCleanup = createExtraDates(
          updatedService.dates,
          enrichedService.blocked_dates
        );
        // console.log("datesCleanup", datesCleanup);
        updatedService.dates = datesCleanup.dates;
        updatedService.hasAppliedBockedDays = datesCleanup.hasAppliedBockedDays;
        // }

        Object.assign(updatedService, {
          type: enrichedService.type,
          description: enrichedService.description,
          name: enrichedService.name,
          availabilityQuantity: enrichedService.availibility_quantity,
          availabilityMode: enrichedService.availability_mode,
          quantityInfo: enrichedService.quantity_info,
          pricingInfo: enrichedService.pricing_info,
          pricingUnit: enrichedService.pricing_unit,
          blockedDates: enrichedService.blocked_dates,
          bookableUntilHoursBeforeArrival:
            enrichedService.bookable_until_hours_before_arrival,
          activityInfo: enrichedService.activity_info,
          usageCode: enrichedService.usage_code,
          partner: enrichedService.partner,
          overviewImage: enrichedService.overview_image,
        });
        return updatedService;
      })
      .filter(function (updatedService) {
        // we remove the falsy filters, those stem from the return false when !serviceAdditions are not found
        return !!updatedService;
      });
    // console.log("enrichedServices", enrichedServices);
    return enrichedServices;
  }

  enrichServiceWithActivityStatus(services, requestedActivities) {
    // if no requestedActivities, we return the services as is
    if (!requestedActivities) {
      return services;
    }
    let enrichedServices = services.map((service) => {
      let activityStatus = requestedActivities[service.service.code];
      if (activityStatus) {
        Object.assign(service, {
          activityStatus: activityStatus,
        });
      }
      return service;
    });

    return enrichedServices;
  }

  async getBookableServicesForReservation(
    reservationId,
    servicesAdditions,
    requestedActivities
  ) {
    return axios
      .get(
        `${RAUS_API_URL}/api/companion/bookable-services?reservationId=${reservationId}`
      )
      .then(async (response) => {
        // enrich the bookable services with activity request status
        let bookableServices = this.enrichServiceWithActivityStatus(
          response.data.services,
          requestedActivities
        );
        // enrich the bookable services with info from metadata
        bookableServices = await this.enrichServiceFromMetadata(
          response.data.services,
          servicesAdditions
        );
        return bookableServices;
      })
      .catch(function (error) {
        // no services for reservation, so... not an actual error
        Sentry.captureException(error);
        // if (error.response?.status === 404) {
        return [];
        // } else {
        //   // console.log('error2', error)
        //   return []
        // }
      });
  }

  async getBookedServices(reservationId, servicesAdditions) {
    return axios
      .get(
        `${RAUS_API_URL}/api/companion/booked-services?reservationId=${reservationId}`
      )
      .then(async (response) => {
        // console.log("response.data", response.data);
        // enrich the bookable services with info from metadata
        const bookedServices = await this.enrichServiceFromMetadata(
          response.data,
          servicesAdditions
        );

        return bookedServices;
      })
      .catch(function (error) {
        // no services for reservation, so... not an actual error
        Sentry.captureException(error);
        // if (error.response?.status === 404) {
        return [];
        // } else {
        //   // console.log('error2', error)
        //   return []
        // }
      });
  }

  // await ServiceService.bookService(reservationId, serviceId, [
  //     { "serviceDate": "2023-04-14", "amount": { "currency": "EUR", "amount": 12 }, "count": 1 },
  //     { "serviceDate": "2023-04-15", "amount": { "currency": "EUR", "amount": 12 }, "count": 1 }
  // ]);
  async bookService(reservationId, servicesId, count, date = null) {
    let payload = {
      serviceId: servicesId,
      count: count,
    };
    if (date?.length) {
      payload = {
        ...payload,
        dates: date.map((serviceDate) => ({
          serviceDate,
        })),
      };
    }

    return axios
      .post(
        "https://hook.eu1.make.com/f2eccxp8va2mgnu4fadd33iu6qh2a4ov/?reservationId=" +
          reservationId,
        payload
      )
      .then((response) => {
        // console.log("response from booking service: ", response);
        return true;
      })
      .catch(function (error) {
        Sentry.captureException(error);
        return false;
      });
  }

  async getServicesAdditions(spotCode, lang) {
    // console.log('getServicesAdditions lang', lang)
    // always get EN here as we only care about the restrictions
    try {
      const response = await axios.get(METADATA_URL, {
        headers: { Authorization: "Bearer " + METADATA_TOKEN },
        params: {
          // this filter is not working and its a da before christmas. filtering a little further down instead
          "filters[spot_code][$eq]": spotCode,
          // "filters[services][channelcode_companion][$eq]": "true",
          // "filters[services][type][$ne]": "Experience-on-request",
          locale: "en",
          fields: "spot_code",
          "populate[services][populate][0]": "localizations",
          "populate[services][populate][1]": "blocked_dates",
          "populate[services][populate][2]": "overview_image.image",
          "populate[services][populate][3]": "activity_info",
          "populate[services][populate][4]": "partner",
          "populate[services][populate][5]": "usage_code",
          "populate[services][populate][6]": "localizations.activity_info",
        },
      });

      const servicesFromResponse =
        response.data?.data[0]?.attributes?.services?.data || [];

      // this filter should not need to be here but i am lazy today
      const services = servicesFromResponse
        .filter((service) => {
          // only services that are enabled for companion OR that are enabled for IBE and are LATE_CHE (late checkin for sunday 2 night bookings)
          return (
            service.attributes.channelcode_companion === true ||
            (service.attributes.channelcode_ibe === true &&
              service.attributes.service_code === "LATE_CHE")
          );
        })
        .map((service) => {
          let {
            blocked_dates,
            activity_info,
            usage_code,
            localizations,
            overview_image,
            ...enContent
          } = service.attributes;
          let localizedContent = enContent;

          if (lang !== "en") {
            localizedContent = service.attributes.localizations.data.find(
              (localization) => {
                return localization.attributes.locale === lang;
              }
            ).attributes;

            activity_info =
              localizedContent.activity_info &&
              localizedContent.activity_info !== null
                ? localizedContent.activity_info
                : enContent.activity_info;
          }

          return {
            code: service.attributes.service_code,
            blocked_dates: blocked_dates,
            activity_info: activity_info,
            usage_code: usage_code,
            partner: service.attributes.partner?.data
              ? service.attributes.partner.data.attributes
              : null,
            overview_image: overview_image,
            ...localizedContent,
          };
        });
      // console.log("getServicesAdditions", services);
      return services;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }

  // async getBookedServices(reservationId) {
  //   return axios
  //     .get(
  //       `${RAUS_API_URL}/api/companion/booked-services/?reservationId=${reservationId}`
  //     )
  //     .then((response) => {
  //       console.log("response from booked services: ", response);
  //       return response;
  //     })
  //     .catch(function (error) {
  //       Sentry.captureException(error);
  //       return false;
  //     });
  // }
}

// TODO-LUCCA: Find a solution how we can still transport the prices while still being able to block extras
const createExtraDates = (dates, blockedDates) => {
  if (!blockedDates || blockedDates.length === 0) {
    return {
      dates: dates,
      hasAppliedBockedDays: false,
    };
  }

  const datesAfterRemovingBlockedDates = removeBlockedDatesFromDates(
    dates,
    blockedDates
  );

  return {
    dates: datesAfterRemovingBlockedDates,
    hasAppliedBockedDays: datesAfterRemovingBlockedDates.length < dates.length,
  };
};

// https://chat.openai.com/share/b799929b-62b7-4d10-9fea-1a6d81a2a398
const removeBlockedDatesFromDates = (dates, blockedDates) => {
  // console.log("removeBlockedDatesFromDates dates", dates);
  // console.log("removeBlockedDatesFromDates blockedDates", blockedDates);
  return dates.filter((date) => {
    // console.log("  dates.filter date", date.serviceDate);
    const isInBlockedDate = blockedDates.some((blockedDate) => {
      // console.log(
      //   "  -> dayjs(date.serviceDate)",
      //   dayjs(date.serviceDate).format("YYYY-MM-DD")
      // );
      // console.log(
      //   "  -> dayjs(blockedDate.from)",
      //   dayjs(blockedDate.from).format("YYYY-MM-DD")
      // );
      // console.log(
      //   "  -> dayjs(blockedDate.to)",
      //   dayjs(blockedDate.to).format("YYYY-MM-DD")
      // );
      const isIn = dayjs(date.serviceDate).isBetween(
        dayjs(blockedDate.from),
        dayjs(blockedDate.to),
        "day",
        "[]" // including start and end
      );
      // console.log("  dates.filter isIn", isIn);
      return isIn;
    });
    return !isInBlockedDate;
  });
};

export default new ServiceService();
