import { useAppContext } from "provider/AppProvider";
import dict from "../../utils/translations";
import ExtrasCard from "../ExtrasCard";
import BookableExtras from "./BookableExtras";
import {
  ContentBox,
  ExtrasCardWrapper,
  InfoBox,
} from "./styles/Contents.styled";

function ExtrasSection({ reservation, extraContentItem, extraTypes }) {
  const { lang } = useAppContext();
  const reservationId = reservation.id;

  // console.log("extraTypes", extraTypes);

  const hasAvailableBookableServicesForExtraTypes = () => {
    return availableExtrasWithoutStatus.some((extra) =>
      extraTypes.includes(extra.type)
    );
  };

  let bookedExtrasForGivenTypes = reservation?.bookedServices?.filter(
    (service) => {
      const { type, code } = service;
      return (
        extraTypes.indexOf(type) > -1 &&
        code !== "PETS" &&
        code !== "AD_DOG_CL7" &&
        code !== "AD_SN_200" &&
        code !== "MOBILITY" &&
        code !== "AD_SS_C56" &&
        code !== "KLIM"
      );
    }
  );
  // console.log("bookedExtrasForGivenTypes", bookedExtrasForGivenTypes);

  // Include bookableServices that have activityStatus set to "booked", exclude PETS & AD_DOG_CL7 from being rendered in this section
  const bookedBookableServices = reservation?.bookableServices?.filter(
    (service) => {
      const { type, activityStatus, code } = service;
      return (
        extraTypes.indexOf(type) > -1 &&
        activityStatus?.status === "booked" &&
        code !== "PETS" &&
        code !== "AD_DOG_CL7" &&
        code !== "AD_SN_200" &&
        code !== "MOBILITY" &&
        code !== "AD_SS_C56" &&
        code !== "KLIM"
      );
    }
  );

  bookedExtrasForGivenTypes = [
    ...(bookedExtrasForGivenTypes || []),
    ...(bookedBookableServices || []),
  ];

  if (!bookedExtrasForGivenTypes || bookedExtrasForGivenTypes.length === 0) {
    bookedExtrasForGivenTypes = null;
  }

  // console.log("reservation.bookableServices: ", reservation.bookableServices);
  // Filter the reservation.bookableServices list to get the available extras that match the given extraTypes, exclude late dep and early arr
  const availableExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.code !== "LATE_CHE" &&
        extra.code !== "LATE_DEP" &&
        extra.code !== "EARLY_ARR"
      );
    }
  );
  // console.log("availableExtrasForGivenTypes", availableExtrasForGivenTypes);

  // needed to hide the button bookmore if no more bookable experiences
  const availableExtrasWithoutStatus = availableExtrasForGivenTypes?.filter(
    (extra) => !extra.activityStatus
  );
  //display requested activities
  const requestedExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.activityStatus &&
        extra.activityStatus?.status === "requested"
      );
    }
  );

  // Filter the reservation.bookableServices list to get the denied extras that match the given extraTypes
  const deniedExtrasForGivenTypes = reservation?.bookableServices?.filter(
    (extra) => {
      return (
        extraTypes.indexOf(extra.type) > -1 &&
        extra.activityStatus &&
        extra.activityStatus?.status === "notBooked"
      );
    }
  );

  return (
    <ContentBox>
      <InfoBox>
        {bookedExtrasForGivenTypes?.map((service, index) => {
          return (
            <ExtrasCardWrapper key={index}>
              <ExtrasCard
                title={service.name}
                showIcon={false}
                service={service}
                showSubTotal={false}
                bookedExtrasForGivenTypes={bookedExtrasForGivenTypes}
                showTotalPrice={true}
                buttonProps={{
                  className: "primary",
                  text: dict("contents.bookedExtraDetails", lang),
                  to: {
                    pathname: `/extra`,
                    search: `reservation=${reservationId}&serviceId=${service.id}&type=booked&extraContentItem=${extraContentItem}`,
                  },
                }}
              />
            </ExtrasCardWrapper>
          );
        }) || []}
      </InfoBox>
      {requestedExtrasForGivenTypes &&
        requestedExtrasForGivenTypes.length > 0 && (
          <InfoBox>
            {requestedExtrasForGivenTypes.map((service, index) => (
              <ExtrasCardWrapper key={index}>
                <ExtrasCard
                  title={service.name}
                  showIcon={false}
                  service={service}
                  showSubTotal={false}
                  buttonProps={{
                    className: "primary",
                    text: dict("contents.bookedExtraDetails", lang),
                    to: {
                      pathname: `/extra`,
                      search: `reservation=${reservationId}&serviceId=${service.id}&type=requested&extraContentItem=${extraContentItem}`,
                    },
                  }}
                />
              </ExtrasCardWrapper>
            ))}
          </InfoBox>
        )}
      {deniedExtrasForGivenTypes.length > 0 && (
        <InfoBox>
          {deniedExtrasForGivenTypes.map((service, index) => (
            <ExtrasCardWrapper key={index}>
              <ExtrasCard
                title={service.name}
                showIcon={false}
                service={service}
                showSubTotal={false}
                buttonProps={{
                  className: "primary",
                  text: dict("contents.bookedExtraDetails", lang),
                  to: {
                    pathname: `/extra`,
                    search: `reservation=${reservationId}&serviceId=${service.id}&type=notBooked&extraContentItem=${extraContentItem}`,
                  },
                }}
              />
            </ExtrasCardWrapper>
          ))}
        </InfoBox>
      )}
      <BookableExtras
        extraContentItem={extraContentItem}
        extraTypes={extraTypes}
      />
    </ContentBox>
  );
}

export default ExtrasSection;
