import { isEqual } from "lodash";
import { useCallback, useEffect } from "react";
import AuthService from "services/auth.service";
import useStore from "../utils/zustand-store";
import { useGetBookingData } from "./useGetBookingData";

const HOURS = 1;
const CHECK_INTERVAL = HOURS * 60 * 60 * 1000;

export const usePeriodicBookingUpdate = (interval = 60000) => {
  const { getBookingData } = useGetBookingData();
  const user = useStore((state) => state.user);
  const getReservations = useStore((state) => state.getReservations);
  const setReservations = useStore((state) => state.setReservations);

  const updateBookings = useCallback(
    async (force = false) => {
      if (!user) return;

      const lastUpdateTimestamp = localStorage.getItem("lastBookingUpdate");
      const currentTime = new Date().getTime();

      if (
        !force &&
        lastUpdateTimestamp &&
        currentTime - parseInt(lastUpdateTimestamp) < CHECK_INTERVAL
      ) {
        // console.log("Skipping update: less than 24 hours since last update");
        return;
      }

      // get all reservations from store by default
      let currentReservations = getReservations();
      if (!currentReservations || !currentReservations[0]?.id) {
        return;
      }
      let reservationsFromBackend = false;

      // get all reservations from backend, this also fetches the booked services as `services`, but of course unenriched
      const bookingData = await AuthService.getBooking(
        user.email,
        currentReservations[0].id
      );
      if (bookingData.success) {
        reservationsFromBackend = await getBookingData({
          user: bookingData.user,
          reservations: bookingData.reservations,
        });
        // console.log("Reservations from backend fetch", reservationsFromBackend);
      }

      if (
        !reservationsFromBackend &&
        !isEqual(currentReservations, reservationsFromBackend)
      ) {
        // console.log("updating reservations", reservationsFromBackend);
        setReservations(reservationsFromBackend);
      }

      localStorage.setItem("lastBookingUpdate", currentTime.toString());
    },
    [user, getReservations, getBookingData, setReservations]
  );

  useEffect(() => {
    // Commented out periodic update
    // const intervalId = setInterval(() => updateBookings(true), interval);

    // Expose updateBookings to the window object
    window.updateBookings = () => updateBookings(true);

    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // console.log("Page became visible, checking if update is needed");
        updateBookings();
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initial check when the component mounts
    updateBookings();

    return () => {
      // clearInterval(intervalId);

      // Remove updateBookings from the window object when the component unmounts
      // delete window.updateBookings;
      // Remove event listener
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [updateBookings, interval]);

  return { updateBookings };
};
