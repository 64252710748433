import logo from "assets/images/logo.svg";
import HelpButton from "atoms/HelpButton";
import { useAppContext } from "provider/AppProvider";
import { useState } from "react";
import { useReservationFromQueryParams } from "utils/helper";
import { HeaderWrapper, Logo, StyledLink } from "./styles/Header.styled";

function Header() {
  const { lang, user, getReservations } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  const reservations = getReservations();

  const logoLink =
    reservations.length > 1
      ? "/"
      : "/reservation?reservation=" + reservation.id;
  const [sticky, setSticky] = useState(true);

  return (
    <HeaderWrapper sticky={sticky}>
      <StyledLink to={logoLink}>
        <Logo src={logo} alt="logo" />
      </StyledLink>
      {/* <button onClick={toggleLanguage}>{lang}</button> */}

      <HelpButton reservation={reservation}></HelpButton>
    </HeaderWrapper>
  );
}

export default Header;
