import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import HeaderSubpage from "../components/HeaderSubpage";
import { StickyWrapper } from "../components/styles/Header.styled";
import dict from "../utils/translations";

import BookableExtras from "../components/Contents/BookableExtras";

function BookExtras() {
  const { getReservation } = useAppContext();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const extraContentItem = params?.extraContentItem;
  const extraTypes = params?.extraTypes;
  const reservation = getReservation(reservationId);

  const { lang } = useAppContext();

  return (
    <div>
      <StickyWrapper>
        <HeaderSubpage
          h5Text={dict(`extraContentItems.${extraContentItem}.title`, lang)}
          h2Text={dict(
            `extraContentItems.${extraContentItem}.toBookingsButton`,
            lang
          )}
          overrideBackLink={`/reservation?reservation=${reservationId}`}
        />
      </StickyWrapper>
      <BookableExtras
        reservation={reservation}
        extraContentItem={extraContentItem}
        extraTypes={extraTypes}
      />
    </div>
  );
}

export default BookExtras;
