import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set, get) => ({
      lang: "de",
      user: null,
      reservations: [],
      hasWatchedVideoOnce: false,
      setUser: (user) => set({ user }),
      setLang: (lang) => set({ lang }),
      setReservations: (reservations) => set({ reservations }),
      getReservations: () => get().reservations,
      getReservationById: (id) => {
        const state = useStore.getState();
        return state.reservations.find((reservation) => reservation.id === id);
      },
      patchReservationById: (id, updatedFields) => {
        const reservations = get().reservations;
        const updatedReservations = reservations.map((reservation) =>
          reservation.id === id
            ? { ...reservation, ...updatedFields }
            : reservation
        );
        set({ reservations: updatedReservations });
        console.log(" in STORE updatedReservations", updatedReservations);
        return updatedReservations;
      },
      clearStore: () =>
        set({
          user: null,
          reservations: [],
          hasWatchedVideoOnce: false,
        }),
      setHasWatchedVideoOnce: (hasWatchedVideoOnce) =>
        set({ hasWatchedVideoOnce }),
      getHasWatchedVideoOnce: () => get().hasWatchedVideoOnce,
      getState: () => get(),
    }),
    {
      name: "app-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useStore;
