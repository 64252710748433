const getCompanionContent = (
  identifier,
  locationContent,
  unitGroupContent,
  cabinContent
) => {
  const checkByIdentifier = (content) => content.identifier === identifier;

  const contentInCabin = cabinContent?.find(checkByIdentifier);
  if (contentInCabin) return contentInCabin;

  const contentInUnitGroup = unitGroupContent?.find(checkByIdentifier);
  if (contentInUnitGroup) return contentInUnitGroup;

  const contentInLocation = locationContent?.find(checkByIdentifier);
  if (contentInLocation) return contentInLocation;

  return false;
};

export default getCompanionContent;
