import picture from "assets/images/illu-blumen.svg";
import logo from "assets/images/logo.svg";
import ButtonLink from "atoms/ButtonLink";
import { ExtraBox } from "components/Contents/styles/Contents.styled";
import { useAppContext } from "provider/AppProvider";
import styled from "styled-components";
import dict from "utils/translations";
import { useNavigationPath } from "../hooks/useNavigationPath";
import { useReservationFromQueryParams } from "../utils/helper";
import {
  FormWrapper,
  Image,
  LoginBox,
  LoginIntro,
  LoginText,
  LoginTitle,
  LoginWrapper,
  Logo,
} from "./styles/Login.styled";

const ButtonWrapper = styled(ExtraBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

function Welcome() {
  const { reservationId, reservation } = useReservationFromQueryParams();

  return (
    <LoginWrapper className="animate">
      <Logo src={logo} alt="logo" />
      <Image src={picture} alt="picture" />
      <LoginBox>
        <WelcomeBoxContent reservation={reservation} />
      </LoginBox>
    </LoginWrapper>
  );
}

function WelcomeBoxContent({ reservation }) {
  const { lang, user } = useAppContext();

  const buildPath = useNavigationPath();

  const pathToReservation = buildPath("/reservation", {
    reservation: reservation.id,
  });

  if (reservation.checkInStatus) {
    return (
      <>
        <LoginTitle>
          {dict("pages.welcomeTitle", lang)} {user.firstName},
        </LoginTitle>
        <LoginText>{dict("pages.welcomeIntro", lang)}</LoginText>
        <LoginIntro>{dict("pages.defaultWelcomeText", lang)}</LoginIntro>
        <FormWrapper>
          <ButtonLink
            className="greenFull"
            icon={null}
            caption={dict("pages.defaultWelcomeButton", lang)}
            to={pathToReservation}
          ></ButtonLink>
        </FormWrapper>
      </>
    );
  }

  return (
    <>
      <LoginTitle>
        {dict("pages.welcomeTitle", lang)} {user.firstName},
      </LoginTitle>
      <LoginText>{dict("pages.welcomeIntro", lang)}</LoginText>
      <LoginIntro>{dict("pages.welcomeText", lang)}</LoginIntro>
      <FormWrapper>
        <ButtonWrapper>
          <ButtonLink
            className="greenFull"
            icon={null}
            caption={dict("pages.welcomeButton", lang)}
            to={buildPath("/check-in", { reservation: reservation.id })}
          ></ButtonLink>
          <ButtonLink
            className="whiteFull"
            icon={null}
            caption={dict("pages.checkinLater", lang)}
            to={pathToReservation}
          />
        </ButtonWrapper>
      </FormWrapper>
    </>
  );
}

export default Welcome;
