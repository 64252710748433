import * as Sentry from "@sentry/browser";

import axios from "axios";

class requestActivityService {
  async getActivityStatusFromHubspot(reservationId) {
    // status mapping from `Guest Experience` Ticket Pipeline in HubSpot
    // https://app-eu1.hubspot.com/pipelines-settings/25939496/object/0-5/25144271
    // 171707092 = Warten auf Gast
    // 171897564 = Not started
    // 184377796 = Extra nicht buchbar
    // 171707091 = Warten auf Intern
    // 171708655 = In Bearbeitung
    // 171707093 = Erledigt
    return axios
      .get(
        `https://hook.eu1.make.com/qrmgkyvyimckuavwxne2hxrdzda63aow?reservationId=${reservationId}`
      )
      .then((response) => {
        // In this code, the reduce() method iterates over each object in the array and uses
        // the service_code property as the key and the status property as the value in the
        // resulting object. The initial value of the accumulator ({}) is an empty object.
        const namedService = response.data.reduce((acc, obj) => {
          acc[obj.service_code] = obj;
          return acc;
        }, {});
        console.log("getActivityStatusFromHubspot namedService", namedService);
        return namedService;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }

  async requestActivity(reservationId, serviceCode, preferredTime) {
    return axios
      .get(
        `https://hook.eu1.make.com/qwd7bsg7bnp79hstl5ea82dqp2fy260i?reservationId=${reservationId}&serviceCode=${serviceCode}&preferredTime=${preferredTime}`
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        Sentry.captureException(error);
      });
  }
}

export default new requestActivityService();
