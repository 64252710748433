import BookedCabins from "./BookedCabins";
import { ContentBox, InfoBox } from "./styles/Contents.styled";

function ReservationsItem({ reservation }) {
  return (
    <ContentBox>
      <InfoBox>
        <BookedCabins reservation={reservation} />
      </InfoBox>
    </ContentBox>
  );
}

export default ReservationsItem;
