import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import getCompanionContent from "utils/content";
import { useReservationFromQueryParams } from "utils/helper";
import dict from "utils/translations";

function Safety() {
  const { lang } = useAppContext();

  const { reservationId, reservation } = useReservationFromQueryParams();

  const contentItem = getCompanionContent(
    "safety",
    reservation.locationContent,
    reservation.unitGroupContent,
    reservation.cabinContent
  );

  const emergencyPageUrl = "/emergency?reservation=" + reservationId;
  const safetyContent = contentItem.content.replace(
    /%emergencyPage%/,
    emergencyPageUrl
  );

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.safety", lang)}
      subTitle={dict("contentItems.help.title", lang)}
      content={safetyContent}
    />
  );
}

export default Safety;
