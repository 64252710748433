import logo from "assets/images/logo.svg";
import HelpButton from "atoms/HelpButton";
import { TitleL } from "atoms/styles/Collapsible.styled";
import { InfoBox, Text } from "components/Contents/styles/Contents.styled";
import HeaderSubpage from "components/HeaderSubpage";

import {
  HeaderWrapper,
  Logo,
  StickyWrapper,
  StyledLink,
} from "components/styles/Header.styled";
import getCompanionContent from "utils/content";
import dict from "utils/translations";

const PageLayoutWithWifi = ({
  reservations,
  reservation,
  lang,
  isHeaderSubPageVisible,
  title,
  subTitle,
  wifiTitle,
  wifiName,
  wifiPw,
  wifiText,
  content,
  contentId,
  children,
}) => {
  const numBookings = reservations.length;
  const logoLink =
    numBookings > 1 ? "/" : "/reservation?reservation=" + reservation.id;

  const contentItem = getCompanionContent(
    contentId,
    reservation.locationContent,
    reservation.unitGroupContent,
    reservation.cabinContent
  );

  return (
    <div>
      <HeaderWrapper sticky={false}>
        <StyledLink to={logoLink}>
          <Logo src={logo} alt="logo" />
        </StyledLink>
        {/* <button onClick={toggleLanguage}>{lang}</button> */}
        <HelpButton reservation={reservation}></HelpButton>
      </HeaderWrapper>
      <StickyWrapper>
        {isHeaderSubPageVisible ? (
          <HeaderSubpage h5Text={subTitle} h2Text={title} />
        ) : null}
      </StickyWrapper>
      <InfoBox>
        <TitleL>{wifiTitle}</TitleL>
        <InfoBox>
          <Text>
            {dict("contents.wifiName", lang)} {wifiName}
          </Text>
        </InfoBox>
        <InfoBox>
          <Text>
            {dict("contents.wifiPassword", lang)} {wifiPw}
          </Text>
        </InfoBox>
        <InfoBox>
          <Text>{wifiText}</Text>
        </InfoBox>
        {children ? (
          children
        ) : (
          <Text
            className="subpageContent"
            dangerouslySetInnerHTML={{
              __html: content ? content : contentItem.content,
            }}
          />
        )}
      </InfoBox>
    </div>
  );
};

export default PageLayoutWithWifi;
