import { useAppContext } from "../../provider/AppProvider";
import { useReservationFromQueryParams } from "../../utils/helper";
import dict from "../../utils/translations";
import { H3noP, InfoBox, Px } from "./styles/Contents.styled";

function BookingRateContent() {
  const { lang } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  console.log(reservation);

  return (
    <div>
      <InfoBox>
        <H3noP>{reservation.ratePlan.name[lang]}</H3noP>
        <Px>{reservation.ratePlan.description[lang]}</Px>
      </InfoBox>
      <InfoBox>
        <H3noP>
          {dict("pages.cancellationFee", lang)}{" "}
          {reservation.cancellationPolicy.name[lang]}
        </H3noP>
        <Px>{reservation.cancellationPolicy.description[lang]}</Px>
      </InfoBox>
      <InfoBox>
        <H3noP>
          {dict("pages.noShowFee", lang)} {reservation.noShowPolicy.name[lang]}
        </H3noP>
        <Px>{reservation.noShowPolicy.description[lang]}</Px>
      </InfoBox>
    </div>
  );
}

export default BookingRateContent;
