import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import getCompanionContent from "utils/content";
import { useReservationFromQueryParams } from "utils/helper";
import dict from "utils/translations";

function Emergency() {
  const { lang, shouldShowHostInfo } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  const showHostInfo = shouldShowHostInfo(reservation);

  const emergencyContent = getCompanionContent(
    "emergency",
    reservation.locationContent,
    reservation.unitGroupContent,
    reservation.cabinContent
  );

  let emergencyContactHost20Content = getCompanionContent(
    "emergency_contact_host20",
    reservation.locationContent,
    reservation.unitGroupContent,
    reservation.cabinContent
  );

  const emergencyContactRausContent = getCompanionContent(
    "emergency_contact_raus",
    reservation.locationContent,
    reservation.unitGroupContent,
    reservation.cabinContent
  );

  let content = emergencyContent.content;
  if (
    showHostInfo &&
    reservation?.hubSpotDeal?.host &&
    reservation?.hubSpotDeal?.host?.display_name &&
    reservation?.hubSpotDeal?.host?.guest_contact_phone
  ) {
    emergencyContactHost20Content = emergencyContactHost20Content.content
      .replace(/%hostDisplayName%/g, reservation.hubSpotDeal.host.display_name)
      .replace(
        /%hostGuestContactPhone%/g,
        reservation.hubSpotDeal.host.guest_contact_phone
      );
    content = `${content}<br>${emergencyContactHost20Content}`;
  } else {
    content = `${content}<br>${emergencyContactRausContent.content}`;
  }

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.emergency", lang)}
      subTitle={dict("contentItems.help.title", lang)}
      content={content}
    />
  );
}

export default Emergency;
