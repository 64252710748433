import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { usePostHog } from "posthog-js/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import house from "assets/images/illu-haus.svg";
import logo from "assets/images/logo.svg";
import { H } from "components/Contents/styles/Contents.styled";
import { useGetBookingData } from "hooks/useGetBookingData";
import { useAppContext } from "provider/AppProvider";
import AuthService from "services/auth.service";
import { pushToHubSpot } from "utils/gtm";
import dict from "utils/translations";
import { useNavigationPath } from "../hooks/useNavigationPath";
import { getCompletePathFromURL } from "../utils/getCompletePathFromURL";
import useStore from "../utils/zustand-store";

import {
  Button,
  ButtonContainer,
  DataInput,
  Error,
  FormWrapper,
  Image,
  InputContainer,
  LoginBox,
  LoginIntro,
  LoginTitle,
  LoginWrapper,
  Logo,
} from "./styles/Login.styled";

dayjs.extend(isSameOrBefore);

const BookingNumberField = React.memo(
  ({ value, onChange, inputRef, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <DataInput
        ref={inputRef}
        type={isFocused || value.length > 0 ? "text" : "password"}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={value}
        onChange={onChange}
        {...props}
      />
    );
  }
);

const Login = () => {
  const [loginState, setLoginState] = useState({
    error: null,
    attempted: false,
    isSubmitting: false,
  });
  const { getBookingData, isFetching } = useGetBookingData();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const buildPath = useNavigationPath();
  const posthog = usePostHog();

  const { lang, setLang, setUser, getReservations, getHasWatchedVideoOnce } =
    useAppContext();

  const [formData, setFormData] = useState({
    email: decodeURIComponent(searchParams.get("email") || ""),
    bookingNumber: searchParams.get("reservationId") || "",
  });

  const inputRefs = {
    email: useRef(null),
    bookingNumber: useRef(null),
  };

  const submitLockRef = useRef(false);
  const store = useStore();

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const getStayStatus = useMemo(
    () => (departureDateString) => {
      const departureDate = dayjs(departureDateString);
      return {
        isAfterStay: dayjs().isAfter(departureDate),
        isBeforeOrDuringStay: dayjs().isSameOrBefore(departureDate),
      };
    },
    []
  );

  const handleRedirection = useCallback(() => {
    const reservations = getReservations();
    if (!reservations || !reservations[0]?.id) {
      console.error("no booking found");
      return;
    }
    const reservationId = reservations[0].id;

    const stayStatus = getStayStatus(reservations[0].departure);
    const hasWatchedVideoOnce = getHasWatchedVideoOnce();

    if (stayStatus.isAfterStay) {
      return navigate(buildPath("/after-stay", { reservation: reservationId }));
    }

    if (stayStatus.isBeforeOrDuringStay) {
      const redirect = searchParams.get("redirect");
      if (redirect) {
        const url = new URL(`${window.location.origin}${redirect}`);
        url.searchParams.delete("reservationId");
        url.searchParams.delete("email");
        url.searchParams.delete("redirect");
        url.searchParams.set("reservation", reservationId);
        return navigate(getCompletePathFromURL(url));
      }

      if (reservations.length > 1) {
        return navigate(buildPath("/"));
      }

      if (hasWatchedVideoOnce) {
        return navigate(buildPath("/welcome", { reservation: reservationId }));
      }

      return navigate(buildPath("/loading", { reservation: reservationId }));
    }
  }, [
    getReservations,
    getHasWatchedVideoOnce,
    navigate,
    buildPath,
    searchParams,
    getStayStatus,
  ]);

  const safeHandleSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      if (submitLockRef.current) return;
      submitLockRef.current = true;
      setLoginState((prev) => ({
        ...prev,
        isSubmitting: true,
        error: null,
        attempted: true,
      }));

      try {
        store.clearStore();

        const { email, bookingNumber } = formData;
        const loginRequest = await AuthService.getBooking(
          email.trim(),
          bookingNumber.split("-")[0]
        );

        if (loginRequest.success) {
          setUser(loginRequest.user);
          setLang(loginRequest.user.preferredLanguage);

          posthog?.identify(email, { email });
          pushToHubSpot(["identify", { email }]);
          pushToHubSpot(["setPath", window?.location?.pathname]);
          pushToHubSpot(["trackPageView"]);

          await getBookingData({
            user: loginRequest.user,
            reservations: loginRequest.reservations,
          });

          handleRedirection();
        } else {
          throw new Error("Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        setLoginState((prev) => ({ ...prev, error }));
      } finally {
        submitLockRef.current = false;
        setLoginState((prev) => ({ ...prev, isSubmitting: false }));
      }
    },
    [
      formData,
      store,
      setUser,
      setLang,
      posthog,
      getBookingData,
      handleRedirection,
    ]
  );

  useEffect(() => {
    const { email, bookingNumber } = formData;
    if (
      email &&
      bookingNumber &&
      !loginState.isSubmitting &&
      !loginState.attempted
    ) {
      void safeHandleSubmit(null);
    }
  }, [
    formData,
    loginState.isSubmitting,
    loginState.attempted,
    safeHandleSubmit,
  ]);

  return (
    <LoginWrapper>
      <Logo src={logo} alt="logo" />
      <Image src={house} alt="house" />
      <LoginBox>
        <LoginTitle>{dict("login.title", lang)}</LoginTitle>
        <LoginIntro>{dict("login.text", lang)}</LoginIntro>
        {loginState.attempted && loginState.error && (
          <Error>{dict("login.error", lang)}</Error>
        )}
        <FormWrapper>
          <form onSubmit={safeHandleSubmit}>
            <InputContainer>
              <H>{dict("login.mail", lang)}</H>
              <DataInput
                ref={inputRefs.email}
                disabled={isFetching}
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <H>{dict("login.booking_number", lang)}</H>
              <BookingNumberField
                inputRef={inputRefs.bookingNumber}
                disabled={isFetching}
                name="bookingNumber"
                required
                value={formData.bookingNumber}
                onChange={handleInputChange}
              />
            </InputContainer>
            <ButtonContainer>
              <Button
                className="yellow"
                $loading={isFetching}
                disabled={isFetching}
                type="submit"
              >
                {isFetching
                  ? dict("login.wait", lang)
                  : dict("login.log", lang)}
              </Button>
            </ButtonContainer>
          </form>
        </FormWrapper>
      </LoginBox>
    </LoginWrapper>
  );
};

export default React.memo(Login);
