import cabin from "assets/images/cabin.svg";
import down from "assets/images/keyboard_arrow_down.svg";
import up from "assets/images/keyboard_arrow_up.svg";
import ReservationsList from "components/ReservationsList";
import { useAppContext } from "provider/AppProvider";
import { useState } from "react";
import dict from "utils/translations";
import {
  ContentWrapper,
  Image,
  Item,
  Tile,
  TileWrapper,
  Title,
  TitleWrapper,
} from "./styles/Collapsible.styled";

function CollapsibleReservations({
  reservation,
  unit,
  unitGroup,
  property,
  unitGroupContent,
  locationContent,
  ...props
}) {
  const [expanded, setExpanded] = useState(props.expanded);
  const { lang } = useAppContext();

  return (
    <Tile>
      <Item>
        <TileWrapper>
          <Title onClick={() => setExpanded(!expanded)}>
            <TitleWrapper>
              <Image>
                {" "}
                <img src={cabin} alt="" />{" "}
              </Image>{" "}
              {dict(`contentItems.${props.id}.title`, lang)}
            </TitleWrapper>
            <span>
              {expanded ? (
                <img src={down} alt="down-icon" />
              ) : (
                <img src={up} alt="up-icon" />
              )}{" "}
            </span>
          </Title>
        </TileWrapper>
        <ContentWrapper open={expanded}>
          <ReservationsList />
        </ContentWrapper>
      </Item>
    </Tile>
  );
}

export default CollapsibleReservations;
