import image from "assets/images/illu-no-extra.svg";

export const serviceImage = (images) => {
  if (images?.medium) return images.medium.url;
  if (images?.small) return images.small.url;
  if (images?.xsmall) return images.xsmall.url;
  if (images?.xxsmall) return images.xxsmall.url;
  if (images?.xxxsmall) return images.xxxsmall.url;
  return image;
};
