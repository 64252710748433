import axios from "axios";

const RAUS_API_URL = process.env.REACT_APP_RAUS_WEB_ENDPOINT;

class AuthService {
  async getBooking(email, booking_number) {
    return axios
      .get(
        `${RAUS_API_URL}/api/companion/booking?bookingId=${booking_number}&email=${email}`
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log("error in login:", error);
        throw error;
      });
  }
}

export default new AuthService();
