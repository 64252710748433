import { InfoBox, Text } from "components/Contents/styles/Contents.styled";
import HeaderSubpage from "components/HeaderSubpage";
import { StickyWrapper } from "components/styles/Header.styled";
import { contents } from "data/ContentOutline";
import { useAppContext } from "provider/AppProvider";
import getCompanionContent from "utils/content";
import dict from "utils/translations";

function Test() {
  const { lang } = useAppContext();
  const locationContent = JSON.parse(localStorage.getItem("location_content"));
  const cabinContent = JSON.parse(localStorage.getItem("unit_content"));
  const unitGroupContent = JSON.parse(
    localStorage.getItem("unitgroup_content")
  );
  const contentItem = getCompanionContent(
    "test",
    locationContent,
    unitGroupContent,
    cabinContent
  );

  return (
    <div>
      <StickyWrapper>
        {contents[1].id === "myStay" ? (
          <HeaderSubpage
            h5Text={dict(`contentItems.myStay.title`, lang)}
            h2Text={dict("pages.test", lang)}
          />
        ) : null}
      </StickyWrapper>
      <InfoBox>
        <Text
          className="subpageContent"
          dangerouslySetInnerHTML={{ __html: contentItem.content }}
        />
      </InfoBox>
    </div>
  );
}

export default Test;
