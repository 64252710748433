import { h3Green, h5Green } from "pages/styles/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ sticky }) => (sticky ? "12px 0px 12px" : "12px 0px 12px")};
  position: ${({ sticky }) => (sticky ? "sticky" : "static")};
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fdf9ed;
`;

export const HeaderSubpageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 0px;
  z-index: 5;
`;

export const Logo = styled.img`
  max-width: 16vh;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
`;

export const Button = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
`;

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: #fdf9ed;
  z-index: 1;
`;

export const HelpButton = styled.a`
  color: inherit;
  text-decoration: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 6px;

  background: #fcbd1c;
  color: #b318a4;

  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 12px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 1px solid rgba(35, 33, 44, 0.1);
  margin-bottom: 8px;
  padding-bottom: 8px;
  padding: 12px 0px 12px;
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const H2 = styled.div`
  ${h3Green};

  margin-bottom: 4px;
`;

export const H5 = styled.div`
  ${h5Green};
  margin-top: 4px;
  margin-bottom: 0px;
`;
