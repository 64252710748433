import { HelpButton as StyledHelpButton } from "components/styles/Header.styled";
import { useAppContext } from "provider/AppProvider";
import dict from "utils/translations";
import phone from "../assets/images/phone_in_talk.svg";

function HelpButton({ reservation }) {
  const { lang, shouldShowHostInfo } = useAppContext();

  const showHostInfo = shouldShowHostInfo(reservation);

  const phoneNumber =
    showHostInfo && reservation?.hubSpotDeal?.host?.guest_contact_phone
      ? `tel:${reservation.hubSpotDeal.host.guest_contact_phone}`
      : "https://wa.me/493057713570";

  return (
    <StyledHelpButton href={phoneNumber} target="_blank">
      {dict(`pages.${showHostInfo ? "contactHost" : "contact"}`, lang)}
      <img src={phone} alt="phone" width={16} />
    </StyledHelpButton>
  );
}

export default HelpButton;
