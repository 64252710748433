import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../provider/AppProvider";
import { getCompletePathFromURL } from "../utils/getCompletePathFromURL";

/** Use this inside the element prop of a <Route element={}/> to redirect to the Login page before accessing the route.*/
export const ProtectedRoute = ({ children }) => {
  const { user } = useAppContext();
  const location = useLocation();

  if (!user) {
    // Check if we're already on the login page
    if (location.pathname === "/login") {
      return children; // Render login page content
    }

    const loginURL = getLoginURLWithRedirectParam(window.location.href);

    console.info(
      "unauthorized access, redirecting to login page",
      getCompletePathFromURL(loginURL)
    );

    return <Navigate to={getCompletePathFromURL(loginURL)} replace />;
  }

  return children;
};

const getLoginURLWithRedirectParam = (href) => {
  // Building login URL based current url because this way all query params and hashes are preserved.
  const loginURL = new URL(href);
  loginURL.pathname = "/login";

  const redirectURL = new URL(href);
  loginURL.searchParams.set("redirect", getCompletePathFromURL(redirectURL));

  return loginURL;
};
