import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";
import dict from "../utils/translations";
import {
  AlignIcon,
  CardText,
  ThemedCard,
  ThemedCardInfo,
  ThemedCardTitle,
  TopMarginP,
} from "./Contents/styles/Contents.styled";
var striptags = require("striptags");

function NotifyIssues({ daysBeforeArrival }) {
  const { lang, user } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  const cabin = reservation.cabin;

  if (
    daysBeforeArrival > 7 ||
    !cabin?.notify_issues ||
    striptags(cabin?.notify_issues).trim() === ""
  ) {
    return;
  }

  return (
    <ThemedCard theme="black">
      <CardText>
        <ThemedCardInfo>
          <AlignIcon noPadding>
            <div>
              <ThemedCardTitle theme="black">
                {dict("contents.notifyIssuesTitle", lang)}
              </ThemedCardTitle>
              <TopMarginP
                dangerouslySetInnerHTML={{
                  __html: `${cabin.notify_issues}`,
                }}
              />
            </div>
          </AlignIcon>
        </ThemedCardInfo>
      </CardText>
    </ThemedCard>
  );
}

export default NotifyIssues;
