import { ButtonToSmall } from "./styles/ButtonLink.styled";

function ButtonExtra({ className = "", to = "/", text }) {
  return (
    <ButtonToSmall className={className} to={to}>
      {text}
    </ButtonToSmall>
  );
}

export default ButtonExtra;
