import { useAppContext } from "provider/AppProvider";
import ReservationsItem from "./Contents/ReservationsItem";

function ReservationsList() {
  const { lang, user, getReservations } = useAppContext();
  const reservations = getReservations();

  return (
    <div>
      {reservations.map((reservation) => (
        <ReservationsItem key={reservation.id} reservation={reservation} />
      ))}
    </div>
  );
}

export default ReservationsList;
