import ButtonLink from "atoms/ButtonLink";
import { useAppContext } from "provider/AppProvider";
import { useNavigationPath } from "../../hooks/useNavigationPath";

function BookedCabins({ reservation }) {
  const { lang } = useAppContext();
  const buildPath = useNavigationPath();

  return (
    <div>
      <ButtonLink
        caption={`${reservation.cabin?.name} ${reservation.id}`}
        to={buildPath("/welcome", { reservation: reservation.id })}
      />
    </div>
  );
}

export default BookedCabins;
