import BookingRateContent from "components/Contents/BookingRateContent";
import PageLayout from "layouts/PageLayout";
import { useAppContext } from "../provider/AppProvider";
import dict from "../utils/translations";

function BookingRate() {
  const { lang } = useAppContext();

  return (
    <div>
      <PageLayout
        isHeaderSubPageVisible={true}
        title={dict("pages.rate", lang)}
        subTitle={dict("contentItems.myStay.title", lang)}
      />
      <BookingRateContent />
    </div>
  );
}

export default BookingRate;
