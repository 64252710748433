import * as Sentry from "@sentry/react";
import React from "react";
import useStore from "../utils/zustand-store";

class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const store = useStore.getState();
    Sentry.withScope((scope) => {
      scope.setExtra(
        "reservations",
        store.reservations || "No reservations found"
      );
      // scope.setExtra("storeState", JSON.stringify(store, null, 2));
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}

const ErrorFallback = () => {
  return (
    <div>
      <h1>Something went wrong</h1>
      <a href="/login">Please login again</a>
    </div>
  );
};

export default SentryErrorBoundary;
