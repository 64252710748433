import getCompanionContent from "utils/content";
import { ContentBox, InfoBox, Text } from "./styles/Contents.styled";

function Checkout({ reservation }) {
  const contentItem = getCompanionContent(
    "checkout",
    reservation.locationContent,
    reservation.unitGroupContent,
    reservation.cabinContent
  );

  return (
    <ContentBox>
      <InfoBox>
        <Text
          className="subpageContent"
          dangerouslySetInnerHTML={{ __html: contentItem.content }}
        />
      </InfoBox>
    </ContentBox>
  );
}

export default Checkout;
