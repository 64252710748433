import { contents } from "data/ContentOutline";
import PageLayoutWithWifi from "layouts/PageLayoutWithWifi";
import { useAppContext } from "provider/AppProvider";
import dict from "utils/translations";
import { useReservationFromQueryParams } from "../utils/helper";

function Overview() {
  const { lang, getReservations } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  const reservations = getReservations();

  return (
    <PageLayoutWithWifi
      reservations={reservations}
      reservation={reservation}
      lang={lang}
      isHeaderSubPageVisible={contents[1].id === "myStay"}
      wifiTitle={dict("contents.wifi", lang)}
      wifiName={reservation.cabin?.wifi_ssid}
      wifiPw={reservation.cabin?.wifi_password}
      wifiText={dict("contents.wifiText", lang)}
      title={dict("pages.overview", lang)}
      subTitle={dict("contentItems.myStay.title", lang)}
      contentId="overview_where_to_find_what"
    />
  );
}

export default Overview;
