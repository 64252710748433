import Header from "components/Header";
import ListOfReservations from "components/ListOfReservations";
import dayjs from "dayjs";
import { useAppContext } from "provider/AppProvider";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useNavigationPath } from "../hooks/useNavigationPath";
import ShortWelcome from "./ShortWelcome";

// Helper function to determine stay status
const getStayStatus = (departureDateString) => {
  const departureDate = dayjs(departureDateString);
  return {
    isAfterStay: dayjs().isAfter(departureDate),
    isBeforeOrDuringStay: dayjs().isSameOrBefore(departureDate),
  };
};

export default function Reservations() {
  const [redirect, setRedirect] = useState(null);

  const buildPath = useNavigationPath();
  const { lang, user, getReservations } = useAppContext();
  const reservations = getReservations();

  const stayStatus = getStayStatus(reservations[0]?.departure);

  useEffect(() => {
    if (!user) setRedirect("/login");
  }, [user]);

  if (redirect) return <Navigate to={redirect} />;

  //check to make sure that booking and booking['hydra:member'] are not null
  if (!reservations || reservations.length === 0) {
    return <div>No booking found</div>;
  }
  const reservationId = reservations[0].id;

  if (stayStatus?.isAfterStay) {
    return (
      <Navigate
        to={buildPath("/after-stay", {
          reservation: reservationId,
        })}
      />
    );
  }

  if (stayStatus?.isBeforeOrDuringStay && reservations.length === 1) {
    return (
      <Navigate
        to={buildPath("/welcome", {
          reservation: reservationId,
        })}
      />
    );
  }

  // let user choose which reservation they'd like to log in if there are multiple
  return (
    <div>
      <Header />
      <ShortWelcome />
      <ListOfReservations />
    </div>
  );
}
