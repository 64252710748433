import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";
import dict from "utils/translations";

function UrgentFaqs() {
  const { lang, user } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();

  return (
    <PageLayout
      isHeaderSubPageVisible={true}
      title={dict("pages.urgentFaqs", lang)}
      subTitle={dict("contentItems.urgentFaqs.title", lang)}
      contentId="urgent_faqs"
    />
  );
}

export default UrgentFaqs;
