// this is an unsed page, that was used via {/* <Route path="/extra/book" element={<BookExtraCheckout />} /> */} that was used to have the "extra checkout" in a single page. but it's logic is now in `ExtraCheckout`
import * as dayjs from "dayjs";
import { useGetBookingData } from "hooks/useGetBookingData";
import { useAppContext } from "provider/AppProvider";
import queryString from "query-string";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceService from "services/service.service";
import { calculateTotalPrice } from "utils/calculateTotalPrice";
import { formatPrice } from "utils/formatPrice";
import checkboxClicked from "../assets/images/check_box.svg";
import checkbox from "../assets/images/check_box_outline_blank.svg";
import {
  BookActions,
  BookConditions,
  ButtonText,
  CardPriceL,
  CardTitle,
  ExtraBox,
  ExtraBoxS,
  H,
  Note,
  QuantityButtonMinus,
  QuantityButtonPlus,
  QuantityContainer,
  QuantityDisplay,
  TextContainer,
} from "../components/Contents/styles/Contents.styled";
import HeaderSubpage from "../components/HeaderSubpage";
import { Button } from "../components/styles/Button.styled";
import { StickyWrapper } from "../components/styles/Header.styled";
import dict from "../utils/translations";

function BookExtraCheckout() {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const reservationId =
    params?.reservation && params.reservation !== ""
      ? params.reservation
      : params?.reservationId && params.reservationId !== ""
      ? params.reservationId
      : "";
  const serviceId = params?.serviceId;

  const { getReservation, state } = useAppContext();
  const navigate = useNavigate();

  const reservation = getReservation(reservationId);
  // console.log('🚀 ~ file: BookExtraCheckout.js:30 ~ BookExtraCheckout ~ reservation:', reservation)

  const { lang } = useAppContext();
  const bookableService =
    reservation?.bookableServices?.find(({ id }) => id === serviceId) ?? false;
  const {
    availabilityMode = "",
    amount = { grossAmount: 0 },
    count = 0,
    pricingUnit = "",
    dates = [],
    availibilityQuantity = 0,
  } = bookableService || {};

  const isQuantityRequired = useMemo(() => {
    return (
      availabilityMode === "Arrival" &&
      (pricingUnit === "Room" || pricingUnit === "Person")
    );
  }, [availabilityMode, pricingUnit]); // for case 2) and case 4)

  const [quantity, setQuantity] = useState(
    isQuantityRequired &&
      pricingUnit === "Person" &&
      availabilityMode === "Arrival" // case 4)
      ? count
      : isQuantityRequired
      ? 1
      : count
  );

  const [buttonClicked, setButtonClicked] = useState(false); // for disabling button

  const subTotal = useMemo(() => {
    if (availabilityMode === "Daily" && pricingUnit === "Room") {
      // Case 1 availabilityMode="Daily" and pricingUnit="Room"
      if (dates.length > 0 && dates[0].amount && dates[0].amount.grossAmount) {
        return parseFloat(dates[0].amount.grossAmount);
      } else {
        return 0;
      }
    } else if (availabilityMode === "Daily" && pricingUnit === "Person") {
      // Case 3 availabilityMode="Daily" and pricingUnit="Person"
      if (dates.length > 0 && dates[0].amount && dates[0].amount.grossAmount) {
        return parseFloat(dates[0].amount.grossAmount) / count;
      } else {
        return 0;
      }
    } else {
      const qty = isQuantityRequired ? 1 : quantity; // Set quantity to 1 if isQuantityRequired is true
      return parseFloat(
        calculateTotalPrice(
          amount,
          count,
          qty,
          isQuantityRequired,
          pricingUnit,
          availabilityMode
        )
      );
    }
  }, [
    quantity,
    amount,
    count,
    isQuantityRequired,
    pricingUnit,
    availabilityMode,
    dates,
  ]);

  const formattedSubTotal = useMemo(() => {
    if (!subTotal) return "0";
    return subTotal.toFixed(2).replace(".", ",");
  }, [subTotal]);

  const displaySubTotal = useMemo(() => {
    return formatPrice(subTotal);
  }, [subTotal]);

  const totalPrice = useMemo(() => {
    const parsedSubTotal = parseFloat(formattedSubTotal.replace(",", "."));
    let result = 0;

    if (availabilityMode === "Daily" && pricingUnit === "Room") {
      // Case 1 availabilityMode="Daily" and pricingUnit="Room"
      result = parsedSubTotal * dates.length;
    } else if (availabilityMode === "Arrival" && pricingUnit === "Room") {
      // Case 2 availabilityMode="Arrival" and pricingUnit="Room"
      result = parsedSubTotal * quantity;
    } else if (availabilityMode === "Daily" && pricingUnit === "Person") {
      // Case 3 availabilityMode="Daily" and pricingUnit="Person"
      result = parsedSubTotal * dates.length * count;
    } else if (availabilityMode === "Arrival" && pricingUnit === "Person") {
      // Case 4 availabilityMode="Arrival" and pricingUnit="Person"
      result = parsedSubTotal * count * (1 + (quantity - count) / count);
    }

    return result;
  }, [
    formattedSubTotal,
    count,
    quantity,
    availabilityMode,
    pricingUnit,
    dates,
  ]);

  const displayTotalPrice = useMemo(() => {
    return formatPrice(totalPrice);
  }, [totalPrice]);

  const handleChangeQty = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attemptedBooking, setAttemptedBooking] = useState(false);
  const [currentBookingNumber, setCurrentBookingNumber] = useState(false);
  const { isDone, isFetching } = useGetBookingData(
    state.user,
    currentBookingNumber
  );

  const handleCheckboxClick = () => {
    setCheckboxStatus(!checkboxStatus);
    setAttemptedBooking(false);
  };

  const handleButtonClick = async () => {
    setButtonClicked(true);
    setAttemptedBooking(true); // set attemptedBooking to true before setting errorMessage
    const dates = bookableService.dates
      .map(({ serviceDate }) => serviceDate)
      .filter((serviceDate) => {
        return bookableService.blockedDates.reduce((allowed, range) => {
          if (!allowed) return false;
          if (range.from && range.to) {
            const comparedDate = dayjs(serviceDate);
            return (
              comparedDate.isBefore(dayjs(range.from)) &&
              comparedDate.isAfter(dayjs(range.to))
            );
          }
          throw new Error("Something went wrong in blocked range");
        }, true);
      });
    if (checkboxStatus) {
      let result = await ServiceService.bookService(
        reservationId,
        serviceId,
        quantity,
        dates
      );
      if (result) {
        const bookingNumber = reservationId.split("-")[0];
        setCurrentBookingNumber(bookingNumber);
      }
    } else {
      setErrorMessage(dict("contents.conditionsError", lang));
      setButtonClicked(false);
    }
  };

  if (!isFetching && isDone) {
    return navigate(
      `/extra?serviceId=${serviceId}&type=booked&reservation=${reservationId}`
    );
  }

  //if quantity left empty for unlimited (default), the quantity can go up to 10, tbd with BIZ DEV how much we can put here or even make unlimited
  const incrementQuantity = () => {
    if (availabilityMode === "Arrival" && pricingUnit === "Person") {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + count;
        if (availibilityQuantity === null) {
          return newQuantity <= 10 ? newQuantity : prevQuantity;
        } else {
          return newQuantity <= availibilityQuantity
            ? newQuantity
            : prevQuantity;
        }
      });
    } else {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + 1;
        if (availibilityQuantity === null) {
          return newQuantity <= 7 ? newQuantity : prevQuantity;
        } else {
          return newQuantity <= availibilityQuantity
            ? newQuantity
            : prevQuantity;
        }
      });
    }
  };

  const decrementQuantity = () => {
    if (availabilityMode === "Arrival" && pricingUnit === "Person") {
      setQuantity((prevQuantity) => {
        if (prevQuantity - count >= count) {
          return prevQuantity - count;
        } else {
          return prevQuantity;
        }
      });
    } else {
      setQuantity((prevQuantity) => {
        if (prevQuantity - 1 >= 1) {
          return prevQuantity - 1;
        } else {
          return prevQuantity;
        }
      });
    }
  };

  const renderSpecificTextField = () => {
    if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Room" &&
      availibilityQuantity === 1
    ) {
      return null;
    } else if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Room" &&
      availibilityQuantity !== 1
    ) {
      return <p>{dict("contents.quantitySpecific2", lang)}</p>;
    } else if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Person" &&
      availibilityQuantity === 1
    ) {
      return (
        <p>
          {"("}
          {count}{" "}
          {count === 1
            ? dict("contents.person", lang)
            : dict("contents.persons", lang)}
          {")"}
        </p>
      );
    } else if (
      availabilityMode === "Arrival" &&
      pricingUnit === "Person" &&
      availibilityQuantity !== 1
    ) {
      return (
        <p>
          {dict("contents.quantitySpecific4", lang)}
          <br />
          {"("}
          {quantity} {dict("contents.multiply", lang)}
          {count}{" "}
          {count === 1
            ? dict("contents.person", lang)
            : dict("contents.persons", lang)}
          {")"}
        </p>
      );
    } else if (availabilityMode === "Daily" && pricingUnit === "Person") {
      return (
        <p>
          {"("}
          {count}{" "}
          {count === 1
            ? dict("contents.person", lang)
            : dict("contents.persons", lang)}{" "}
          {dict("contents.multiply", lang)}
          {dates.length}{" "}
          {dates.length === 1
            ? dict("contents.night", lang)
            : dict("contents.nights", lang)}
          {")"}
        </p>
      );
    } else if (availabilityMode === "Daily" && pricingUnit === "Room") {
      return (
        <p>
          {"("}
          {dates.length}{" "}
          {dates.length === 1
            ? dict("contents.night", lang)
            : dict("contents.nights", lang)}
          {")"}
        </p>
      );
    }
    return null;
  };

  const isMinusDisabled = quantity === (pricingUnit === "Person" ? count : 1);

  const renderQuantity = () => {
    if (
      // Case 2 and Case 4 if availibilityQuantity=1, no option to choose the quantity of a product, calculates automatically
      // valid for exceptions such as VDS, Meditation, Share Now
      (availabilityMode === "Arrival" && pricingUnit === "Room") ||
      (pricingUnit === "Person" && availibilityQuantity !== 1)
    ) {
      return <p>{dict("contents.moreQuantity", lang)}</p>;
    }
    return null;
  };

  const renderQuantityBlock = () => {
    if (isQuantityRequired && availibilityQuantity !== 1) {
      return (
        <ExtraBox>
          <H>{renderQuantity()}</H>
          <QuantityContainer>
            <QuantityButtonMinus
              onClick={decrementQuantity}
              type="button"
              disabled={isMinusDisabled}
            ></QuantityButtonMinus>
            <QuantityDisplay>{quantity}</QuantityDisplay>
            <QuantityButtonPlus
              onClick={incrementQuantity}
              type="button"
            ></QuantityButtonPlus>
          </QuantityContainer>
        </ExtraBox>
      );
    }
    return null;
  };

  return (
    <div>
      <StickyWrapper>
        <HeaderSubpage
          h5Text={bookableService?.name ?? ""}
          h2Text={dict("contents.bookExtraButton", lang)}
        />
      </StickyWrapper>
      {!!bookableService && (
        <div>
          <ExtraBox>
            <H>{dict("contents.oneEx", lang)}</H>
            <CardTitle>{bookableService.name}</CardTitle>
          </ExtraBox>
          {renderQuantityBlock()}
          <ExtraBox>
            <H>{dict("contents.exPriceFinal", lang)}</H>
            {/* {displaySubTotal}€ {priceType(availabilityMode, pricingUnit, lang)} */}
            {displaySubTotal}€<Note>{renderSpecificTextField()}</Note>
            <ExtraBoxS>
              <CardPriceL>
                {dict("contents.totalPriceH", lang)} {displayTotalPrice}€{" "}
              </CardPriceL>
              {dict("contents.paymentExtra", lang)}
            </ExtraBoxS>
          </ExtraBox>
          <BookConditions>
            <img
              src={checkboxStatus ? checkboxClicked : checkbox}
              alt={checkboxStatus ? "checkClicked" : "check"}
              onClick={handleCheckboxClick}
            />
            <TextContainer>
              {dict("contents.bookConditions", lang)}
              {lang === "de" ? (
                <a
                  href="https://www.raus.life/de/agb-und-widerrufsrecht/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {dict("contents.conditions", lang)}
                </a>
              ) : (
                <a
                  href="https://www.raus.life/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {dict("contents.conditions", lang)}
                </a>
              )}
            </TextContainer>
          </BookConditions>
          {!checkboxStatus && attemptedBooking && (
            <ExtraBox className="error">{errorMessage}</ExtraBox>
          )}
          <BookActions>
            <Button
              onClick={handleButtonClick}
              className="primary"
              $loading={buttonClicked}
            >
              <ButtonText>
                {buttonClicked
                  ? dict("contents.waitExtraBooking", lang)
                  : dict("contents.bookExtraButton", lang)}
              </ButtonText>
            </Button>
          </BookActions>
        </div>
      )}
    </div>
  );
}

export default BookExtraCheckout;
