import cabinIcon from "assets/images/cabin.svg";
import ButtonLink from "atoms/ButtonLink";
import CollapsibleReservation from "atoms/CollapsibleReservation";
import { InfoBoxWithMarginTop } from "components/Contents/styles/Contents.styled";
import Header from "components/Header";
import { contents } from "data/ContentOutline";
import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";
import dict from "../utils/translations";

export default function ExtrasPage({ ...props }) {
  const { lang, user } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  if (!reservation) return null;

  const property = reservation.property;

  if (user && reservation && property) {
    return (
      <div>
        <Header />

        <InfoBoxWithMarginTop>
          {dict("pages.extrasPageIntro", lang)}
        </InfoBoxWithMarginTop>

        {contents
          .filter(
            (contentItem) =>
              contentItem.id === "food" || contentItem.id === "activities"
          )
          .map((contentItem, index) => (
            <CollapsibleReservation
              key={index}
              reservation={reservation}
              contentItem={contentItem}
              // isExpanded={true}
              {...contentItem}
            />
          ))}

        <InfoBoxWithMarginTop>
          <ButtonLink
            caption={dict("pages.toReservation", lang)}
            to={"/reservation?reservation=" + reservationId}
            icon={cabinIcon}
          />
        </InfoBoxWithMarginTop>
      </div>
    );
  }
}
