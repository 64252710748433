import { Button, LogoutContainer } from "pages/styles/Login.styled";
import { usePostHog } from "posthog-js/react";
import { useAppContext } from "provider/AppProvider";
import { useNavigate } from "react-router-dom";
import dict from "utils/translations";
import useStore from "../utils/zustand-store";

function Logout() {
  const { lang } = useAppContext();
  const store = useStore();
  const navigate = useNavigate();
  const posthog = usePostHog();

  function logOut() {
    store.clearStore();

    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.clear();
    }

    posthog?.reset();
    navigate("/login");
  }

  return (
    <LogoutContainer>
      <Button type="button" className="light" onClick={logOut}>
        {dict("atoms.logout", lang)}
      </Button>
    </LogoutContainer>
  );
}

export default Logout;
