import { useAppContext } from "provider/AppProvider";
import dict from "utils/translations";
import { LoginBox, LoginShortText, LoginTitle } from "./styles/Login.styled";

function ShortWelcome() {
  const { lang, user } = useAppContext();

  return (
    <LoginBox>
      <LoginTitle>
        {dict("pages.shortWelcomeTitle", lang)} {user.firstName},{" "}
      </LoginTitle>
      <LoginShortText>{dict("pages.shortWelcomeText", lang)}</LoginShortText>
    </LoginBox>
  );
}

export default ShortWelcome;
