import logo from "assets/images/logo.svg";
import HelpButton from "atoms/HelpButton";
import { InfoBox } from "components/Contents/styles/Contents.styled";
import HeaderSubpage from "components/HeaderSubpage";
import {
  HeaderWrapper,
  Logo,
  StickyWrapper,
  StyledLink,
} from "components/styles/Header.styled";
import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";

const ExtraPageLayout = ({
  isHeaderSubPageVisible,
  title,
  subTitle,
  overrideBackLink,
  children,
}) => {
  const { lang, getReservations } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  const reservations = getReservations();

  const logoLink =
    reservations.length > 1
      ? "/"
      : "/reservation?reservation=" + reservation.id;

  return (
    <div>
      <HeaderWrapper sticky={false}>
        <StyledLink to={logoLink}>
          <Logo src={logo} alt="logo" />
        </StyledLink>
        {/* <button onClick={toggleLanguage}>{lang}</button> */}
        <HelpButton reservation={reservation}></HelpButton>
      </HeaderWrapper>
      <StickyWrapper>
        {isHeaderSubPageVisible ? (
          <HeaderSubpage
            h5Text={subTitle}
            h2Text={title}
            overrideBackLink={overrideBackLink}
          />
        ) : null}
      </StickyWrapper>
      <InfoBox>{children ? children : null}</InfoBox>
    </div>
  );
};

export default ExtraPageLayout;
