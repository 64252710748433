import ButtonLink from "atoms/ButtonLink";
import { useReservationFromQueryParams } from "utils/helper";
import { ContentBox, InfoBox } from "../Contents/styles/Contents.styled";

function TravelInfo({ contents, showSauna, showHotTub }) {
  const { reservationId, reservation } = useReservationFromQueryParams();

  return (
    <ContentBox>
      {Object.keys(contents).map((key, index) => {
        if (
          (key === "sauna" && !showSauna) ||
          (key === "hottub" && !showHotTub)
        ) {
          return null;
        }
        return (
          <InfoBox key={index}>
            <ButtonLink
              caption={contents[key].title}
              to={"/" + key + "?reservation=" + reservationId}
            />
          </InfoBox>
        );
      })}
    </ContentBox>
  );
}

export default TravelInfo;
