import { useCallback, useState } from "react";
import ContentService from "../services/content.service";
import MetadataService from "../services/metadata.service";
import ServiceService from "../services/service.service";
import useStore from "../utils/zustand-store";

export const useGetBookingData = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const setReservations = useStore((state) => state.setReservations);

  const getBookingData = useCallback(
    async ({ user, reservations }) => {
      setIsFetching(true);
      setError(null);

      try {
        const updatedReservations = await Promise.all(
          reservations.map(async (reservation) => {
            // console.log("reservation@useGetBookingData", reservation);
            const spotCode = reservation.property.code;
            const isLodgeAmSee = reservation.property.code === "SPOT29";

            // Fetch all data concurrently
            const [
              servicesAdditions,
              locationContent,
              unitGroupContent,
              cabinContent,
              locationAndCabinData,
            ] = await Promise.all([
              ServiceService.getServicesAdditions(
                spotCode,
                user.preferredLanguage
              ),
              ContentService.getLocationContent(
                spotCode,
                user.preferredLanguage
              ),
              ContentService.getUnitGroupContent(
                reservation.unitGroup.id,
                user.preferredLanguage
              ),
              ContentService.getCabinContent(
                reservation.unit?.id,
                user.preferredLanguage
              ),
              MetadataService.getLocationAndCabin(
                spotCode,
                reservation.unit?.id,
                user.preferredLanguage
              ),
            ]);

            // reuse bookedServices if already existing, otherwise fetch
            // this can be the case when a service is booked in the checkout process as that's
            // calling `ServiceService.getBookedServices` and patches the reservation
            // let bookedServices = reservation.bookedServices;
            // if (!bookedServices) {
            const bookedServices =
              reservation.services?.length > 0
                ? await ServiceService.enrichServiceFromMetadata(
                    reservation.services,
                    servicesAdditions
                  )
                : [];
            // console.log("bookedServices@getBookingData", bookedServices);
            // } else {
            //   console.log("bookedServices already existing");
            // }

            const bookableServices =
              await ServiceService.getBookableServicesForReservation(
                reservation.id,
                servicesAdditions,
                reservation.requestedActivities
              );
            // console.log(
            //   "reservation.requestedActivities",
            //   reservation.requestedActivities
            // );
            // console.log("bookableServices@getBookingData", bookableServices);

            if (isLodgeAmSee) {
              reservation.ratePlan.includedServices.push({
                serviceId: "SPOT29-AD_SAUNA",
              });
            }

            // Handle sauna service
            const saunaIncluded = reservation.ratePlan.includedServices?.find(
              (service) => [`${spotCode}-AD_SAUNA`].includes(service.serviceId)
            );

            if (saunaIncluded) {
              const saunaServiceIndex = bookableServices?.findIndex((service) =>
                ["AD_SAUNA"].includes(service.code)
              );
              if (saunaServiceIndex !== -1) {
                const [saunaService] = bookableServices.splice(
                  saunaServiceIndex,
                  1
                );
                bookedServices.push(saunaService);
              }
            }

            // Prepare cabin data
            const location = locationAndCabinData.location;
            const cabin = locationAndCabinData.cabin;
            // const cabin = false;
            // const cabinContent = null;

            // const location = locationAndCabinData.location;
            // const cabin = cabinData ? cabinData.data.data[0].attributes : null;
            // Return updated reservation
            return {
              ...reservation,
              servicesAdditions,
              bookedServices,
              bookableServices,
              locationContent,
              unitGroupContent,
              location,
              cabin,
              cabinContent,
            };
          })
        );

        setReservations(updatedReservations);
        // console.log("updatedReservations", updatedReservations);

        // Return updated reservations so that usePeriodicBookingUpdate can use them
        // to compare with the current reservations and patch the ones that have changed
        return updatedReservations;
      } catch (error) {
        console.log("error", error);
        setError(error);
        return [];
      } finally {
        setIsFetching(false);
      }
    },
    [setReservations]
  );

  return { getBookingData, isFetching, error };
};
