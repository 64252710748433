import { useAppContext } from "provider/AppProvider";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { useNavigationPath } from "../hooks/useNavigationPath";
import { useReservationFromQueryParams } from "../utils/helper";
import { Slogan, Video, VideoContent } from "./styles/Login.styled";

function Loading() {
  const [redirect, setRedirect] = useState(null);
  const { setHasWatchedVideoOnce } = useAppContext();

  const videoRef = useRef(null);
  const buildPath = useNavigationPath();

  const { reservationId, reservation } = useReservationFromQueryParams();

  useEffect(() => {
    videoRef.current = document.querySelector("video");
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasWatchedVideoOnce(true);
      setRedirect(
        buildPath(`/welcome`, {
          reservation: reservationId,
        })
      );
    }, 5000);
    return () => clearTimeout(timer);
  }, [reservationId]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleEnded = () => {
      setRedirect(
        buildPath(`/welcome`, {
          reservation: reservationId,
        })
      );
    };
    const handlePlay = () => {
      video.parentElement.classList.remove("before-start");
    };
    const handleTimeupdate = () => {
      if (video.currentTime >= 5) {
        video.pause();
        setRedirect(
          buildPath(`/welcome`, {
            reservation: reservationId,
          })
        );
      } else if (video.currentTime >= video.duration - 1) {
        video.parentElement.classList.add("before-end");
      }
    };

    video.addEventListener("ended", handleEnded);
    video.addEventListener("play", handlePlay);
    video.addEventListener("timeupdate", handleTimeupdate);

    return () => {
      video.removeEventListener("ended", handleEnded);
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("timeupdate", handleTimeupdate);
    };
  }, [reservation, videoRef]);

  if (redirect) return <Navigate to={redirect} />;

  const videoSrc =
    reservation.cabin?.name === "Schlossgarten"
      ? "/videos/c5_video_1.mp4"
      : "/videos/general_cabin_video.mp4";

  return (
    <Video className="before-start">
      <VideoContent
        ref={videoRef}
        autoPlay
        muted
        playsInline
        preload="auto"
        src={videoSrc}
      />
      <Slogan>#timewellspent</Slogan>
    </Video>
  );
}

export default Loading;
