import dayjs from "dayjs"; // Make sure to import dayjs
import { usePeriodicBookingUpdate } from "hooks/usePeriodicBookingUpdate";
import jwt_decode from "jwt-decode";
import { createContext, useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { rausToast } from "../utils/toastify";
import dict from "../utils/translations";
import useStore from "../utils/zustand-store";

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
  const store = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const checkOldVersion = useCallback(() => {
    const userInLocalStorage = localStorage.getItem("user") !== null;

    if (userInLocalStorage) {
      // Old version detected
      let reservationId = "";
      let email = "";
      let lang = "de";

      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.token) {
          const decodedToken = jwt_decode(user.token);
          reservationId = decodedToken.bookingNumber || "";
          email = decodedToken.email || "";
          lang = decodedToken.preferredLanguage || "de";
        }
      } catch (error) {
        // console.error("Error parsing user data:", error);
      }

      // Clear localStorage
      store.clearStore();
      localStorage.clear();

      // Construct the login URL
      const loginUrl = `/login?reservationId=${reservationId}&email=${encodeURIComponent(
        email
      )}`;

      // Navigate to the login page
      navigate(loginUrl);

      rausToast.warning(dict("login.expired", lang));
    }
  }, [navigate, store]);

  useEffect(() => {
    checkOldVersion();
  }, [location, checkOldVersion]);

  const setReservations = useCallback(
    (reservations) => {
      store.setReservations(reservations);
    },
    [store]
  );

  const getReservations = useCallback(() => {
    return store.getReservations();
  }, [store]);

  const getReservationById = useCallback(
    (id) => {
      return store.getReservationById(id);
    },
    [store]
  );

  const patchReservationById = useCallback(
    (id, updatedFields) => {
      return store.patchReservationById(id, updatedFields);
    },
    [store]
  );

  usePeriodicBookingUpdate();

  const getDaysBeforeArrival = useCallback((arrivalDate) => {
    const arrival = dayjs(arrivalDate);
    return arrival.diff(dayjs(), "day");
  }, []);

  const shouldShowHostInfo = useCallback(
    (reservation) => {
      const daysBeforeArrival = getDaysBeforeArrival(reservation.arrival);
      const isHost20Booking = reservation?.hubSpotDeal?.is_host20_booking;
      return isHost20Booking && daysBeforeArrival <= 1;
    },
    [getDaysBeforeArrival]
  );

  const value = {
    ...store,
    setReservations,
    getReservations,
    getReservationById,
    patchReservationById,
    getDaysBeforeArrival,
    shouldShowHostInfo,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
