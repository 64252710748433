import * as Sentry from "@sentry/browser";

import axios from "axios";

class checkinService {
  async saveCheckInForm(formValues) {
    try {
      const response = await axios.post(
        `https://hook.eu1.make.com/3ljhb3tg5gtb3gvbnu8lyhfdur6smlrc`,
        JSON.stringify(formValues),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("saveCheckInForm error:", error);
      Sentry.captureException(error);
      throw new Error("Server returned an error status for saveCheckInForm");
    }
  }
}

export default new checkinService();
